/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(".mobile-nav-toggle").click(function(e){
          $(".mobile-navigation-container").toggleClass("animate");
          $(".wrapper").toggleClass("noscroll");
          e.preventDefault();
        });

        $('.wrapper').on('click', function (e) {
            if ($(e.target).closest(".mobile-navigation-container ul").length === 0) {
                $(".mobile-navigation-container").removeClass('animate');
            }
        });


        $(".add-new-student-btn").on('click', function(e){
            

            var currentNumber = $(this).data('numstudents');
            var newNumberStudents = currentNumber + 1;
            var fname = $("#student"+currentNumber+"-form").find('.fname').val();
            var lname = $("#student"+currentNumber+"-form").find('.lname').val();

            


            $("#student"+currentNumber+"-form").slideUp(400);

            $("#student"+currentNumber+"-form-parent").find('.student-name-block').text("Member #"+currentNumber+": "+fname+" "+lname).show();
            $("#student"+newNumberStudents+"-form-parent").removeClass("hidden-student");
            $("#student"+currentNumber+"-form-parent").find('.edit-link-container').show();
            $("#student"+newNumberStudents+"-form").find('input, select').attr("required", true);


            $(this).data('numstudents', newNumberStudents);


            if(newNumberStudents === 4){
                $(this).attr("disabled", true);
                $(this).addClass("btn-disabled");
            }

            e.preventDefault();

        });


        $(".edit-member").on('click', function(e){
            var currentMember = $(this).data('membernumber');

            $("#student"+currentMember+"-form").slideToggle(400);

            e.preventDefault();
        });



        $(".view-member").on('click', function(e){

            var memberID = $(this).data('studentrow');


            $("#member"+memberID).slideDown(300);

            e.preventDefault();


        });


        $(".close-member-row").on('click', function(e){

          var memberID = $(this).data('studentrow');


          $("#member"+memberID).slideUp(300);

          e.preventDefault();

        });

      }
    },
   
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
